<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 순회점검 기본정보 -->
          <c-card title="순회점검 월별결재" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  :editable="false" 
                  type="edit" 
                  name="plantCd" 
                  v-model="patrol.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <!-- 순회일 -->
                <c-datepicker
                  type="month"
                  :editable="false" 
                  label="결재월"
                  name="patrolMonth"
                  v-model="patrol.patrolMonth"
                >
                </c-datepicker>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align: center; padding: 20px; font-weight: 700;">
          점검결과 상세내용은 PC에서 확인하세요.
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrolDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolMonthId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      patrol: {
        saiPatrolMonthId: '',  // 순회점검 월별결재 일련번호
        plantCd: null,  // 사업장 코드
        patrolMonth: '',  // 결재월
        depts: [],
        approvalStatusCd: '',
      },
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.detailUrl = selectConfig.sai.patrol.appr.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiPatrolMonthId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {saiPatrolMonthId: this.popupParam.saiPatrolMonthId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.patrol, _result.data);
        },);
      }
    },
  }
};
</script>
